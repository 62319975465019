var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "seeFlow" },
    [
      _c(
        "el-collapse",
        {
          on: { change: _vm.changeShowList },
          model: {
            value: _vm.showContent,
            callback: function ($$v) {
              _vm.showContent = $$v
            },
            expression: "showContent",
          },
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { name: _vm.column.id } },
            [
              _c("template", { slot: "title" }, [
                _c("div", { staticClass: "top-head" }, [
                  _c("div", { staticClass: "col-tit" }, [
                    _c("span", { staticClass: "txts" }, [
                      _vm._v(_vm._s(_vm.column.name || "查看流程")),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "notices-txt",
                        style: _vm.billStatusStyle,
                      },
                      [_vm._v(_vm._s(_vm.businessData.mdInfo.nodeName))]
                    ),
                  ]),
                ]),
              ]),
              _c("cardList", {
                attrs: {
                  column: _vm.column,
                  data: _vm.businessData,
                  showList: _vm.showList,
                },
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }