<!--
 * @Author: 梁平贤
 * @Date: 2020-07-04 16:55:43
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-02-22 16:36:37
 * @Description: 查看流程
 * @FilePath: \user\src\components\businessDetail\components\columnRendering\seeFlow\index.vue
-->
<template>
  <div class="seeFlow">
    <el-collapse v-model="showContent" @change="changeShowList">
      <el-collapse-item :name="column.id">
        <template slot="title">
          <div class="top-head">
            <div class="col-tit">
              <span class="txts">{{column.name || "查看流程"}}</span>
              <div class="notices-txt" :style="billStatusStyle">{{ businessData.mdInfo.nodeName }}</div>
            </div>
            <!-- <en-icon :name="showList ? 'iconzhankai' : 'iconshouqi'" size="12" class="show-btn"> </en-icon> -->
          </div>
        </template>
        <cardList :column="column" :data="businessData" :showList="showList" />
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { BillStatus } from "@/tools/classes/process/ProcessHeader.js";
import cardList from "./components/cardList";

export default {
  name: "BMSeeFlowColumn",
  components: { cardList },
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      showContent: [],
      showList: false
    };
  },
  computed: {
    // 状态
    billStatusStyle() {
      let color = "#fff";
      switch (this.businessData.mdInfo.billStatus) {
        case BillStatus.unCommit:
          color = "#4794DF";
          break;
        case BillStatus.committed:
        case BillStatus.approved:
          color = "#27C494";
          break;
        case BillStatus.approving:
        case BillStatus.pending:
        case BillStatus.suspension:
          color = "#FFAE2D";
          break;
        case BillStatus.over:
        case BillStatus.obsoleted:
          color = "#646C78";
          break;
        case BillStatus.terminated:
          color = "#F86C6C";
          break;
        default:
          color = "#fff";
          break;
      }
      const style = {
        color: "#fff",
        "background-color": `${color}`// 10%透明度
      };
      return style;
    }
  },
  mounted() {
    if (Number(this.column.foldSet) === 2) {
      this.showContent = [this.column.id];
    }
    console.log(this.businessData, "this.businessData");
  },
  methods: {
    /**
     * @description: 点击展开流程列表
     */
    changeShowList(val) {
      this.showList = !!val.length;
    }
  }
};
</script>

<style lang="scss" scoped>
.seeFlow {
  background: #fff;
  border: 1px solid $field-border-color;
  border-radius: 4px;
  .top-head {
    background-color: #f5f7fa;
    height: 48px;
    padding-left: 21px;
    text-align: left;
    zoom: 1;
    font-size:12px;
    &::after {
      content: "";
      display: block;
      clear: both;
    }
    .txts {
      font-weight: bold;
      float: left;
      line-height: 48px;
    }
    .notices-txt {
      float: left;
      // background: #FFA32B;
      // color: #fff;
      padding: 0 10px;
      border-radius: 4px;
      height: 22px;
      line-height: 22px;
      margin: 13px 0 0 10px;
    }
    .show-btn {
      float: right;
      color: #66a7e5;
      margin: 16px 18px 0 0;
      cursor: pointer;
    }
  }
  .el-collapse{
    border:0;
  }
  /deep/ .el-collapse-item__header{
    background-color: #f5f7fa;
    border: 0;
  }
  .el-collapse-item:last-child{
    margin-bottom: 0;
  }
  .el-collapse {
    border: 0;
  }
  /deep/ .el-collapse-item__header {
    background-color: #f5f7fa;
    border: 0;
  }
  .el-collapse-item:last-child {
    margin-bottom: 0;
  }
  /deep/ .el-collapse-item__content{
    border-bottom:0;
    padding-bottom:0;
    font-size:12px;
  }
  /deep/ .el-collapse-item__wrap{
    border-bottom:0;
  }
  /deep/ .el-icon-arrow-right{
    margin-right: 20px;
    color: #a9b5c6;
  }

}
.seeFlow:hover{
  /deep/ .el-collapse-item__arrow{
    color: #3e90fe;
  }
}
</style>
